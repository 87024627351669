<template>
    <settingLayout>
        <section class="wrap_s_block change_password">
            <div class="password_box">
                <div class="title_section">
                    <h2 class="name_section">Password</h2>
                </div>

                <form action="#" @submit.prevent="goChangePassword" autocomplete="off">
                    <div class="body_ change_password">
                        <div class="wrap_input password">
                            <password-field
                                    id="old-password"
                                    autocomplete="old-password'"
                                    v-model="oldPassword"
                                    label="Current Password"
                                    :error-messages="oldPasswordErrorMessages"
                            ></password-field>
<!--	                        <span class="error-msg" v-if="$v.oldPassword.$error && !$v.oldPassword.maxLength">Password must be 128 characters or less</span>-->
                        </div>

                        <div class="wrap_input password">
                            <password-field
                                    id="new-password"
                                    autocomplete="new-password'"
                                    v-model="newPassword"
                                    label="New Password"
                            ></password-field>
	                        <div class="error-msg" v-if="!$v.newPassword.$error && !$v.newPassword.maxLength">Password must be 128 characters or less</div>
                        </div>

                        <div class="password_complexity">
                            <span class="label">Must include:</span>
                            <div class="wrap_must_symbols">
                                <div :class="[ {active: this.passwordComplexity.upperCase}, 'item_' ]">ABC</div>
                                <div :class="[ {active: this.passwordComplexity.lowerCase}, 'item_']">abc</div>
                                <div :class="[ {active: this.passwordComplexity.nonWordChars}, 'item_']">@#&</div>
                                <div :class="[ {active: newPassword.length > 8}, 'item_']">
                                    8+ Characters
                                </div>
                            </div>
                        </div>
                        <v-btn
                                class="btn_change_pass"
                                :loading="passwordFormLoader"
                                :disabled="!isValidChangePasswordForm || (!$v.newPassword.$error && !$v.newPassword.maxLength)"
                                @click.stop="goChangePassword">
                            Save
	                        <v-progress-circular :width="2" indeterminate></v-progress-circular>
                        </v-btn>
                    </div>
                </form>
            </div>
        </section>
        <section class="wrap_s_block change_email">
            <div class="email_box">
                <div class="title_section">
                    <h2 class="name_section">Email</h2>
                </div>
                <div class="body_ change_email">

                    <div class="this_email" v-if="PROFILE && PROFILE.email">{{ PROFILE.email }}
                        <span v-if="PROFILE.status !== 'active'">(Unconfirmed)</span>
<!--                        <button type="button" @click="changeEmail">Edit</button>-->
                    </div>
<!--                    <button class="resend_confirm_email" @click="resendEmailConfirmation" :disabled="timer > 0">-->
<!--                        Resend confirmation-->
<!--                        <template v-if="timer > 0">-->
<!--                            in {{ timer }} seconds-->
<!--                        </template>-->
<!--                    </button>-->
                </div>
            </div>
        </section>
        <dialogChangeEmail v-if="dialogChangeEmailVisible" v-model="dialogChangeEmailVisible"></dialogChangeEmail>
    </settingLayout>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import settingLayout from "@/layouts/settingLayout";
import PasswordField from "@/components/profile/passwordField";
import {maxLength, required} from "vuelidate/lib/validators";

const dialogChangeEmail = () => import('@/components/dialogs/changeEmail');

export default {
    name: 'security',
    components: {
        settingLayout,
        PasswordField,
	    dialogChangeEmail
    },
    data() {
        return {
            passwordFormLoader: false,
            oldPasswordErrorMessages: [],
            oldPassword: '',
            newPassword: '',
            passwordComplexity: {
                lowerCase: false,
                upperCase: false,
                nonWordChars: false,
                minLength: false
            },
            dialogChangeEmailVisible: false,
            timer: 0
        }
    },
	validations: {
		oldPassword: {
			required,
			maxLength: maxLength(128)
		},
		newPassword: {
			required,
			maxLength: maxLength(128)
		}
	},
    watch: {
        newPassword() {
            // this.passwordComplexity.nonWordChars = !!this.newPassword.match(".*[!@#$%^&*.]");
	        this.passwordComplexity.nonWordChars = !!this.newPassword.match(/[!@#$%^&*().,;:{}_+=<>?|~-]/);
            this.passwordComplexity.upperCase = !!this.newPassword.match(".*[A-Z]");
            this.passwordComplexity.lowerCase = !!this.newPassword.match(".*[a-z]");
            this.passwordComplexity.minLength = this.newPassword.length > 8;
        }
    },
    computed: {
        ...mapGetters(['PROFILE', 'CHANGE_PASSWORD_STATUS']),
        isValidChangePasswordForm() {
            return this.passwordComplexity.lowerCase === true &&
                this.passwordComplexity.upperCase === true &&
                this.passwordComplexity.nonWordChars === true &&
                this.passwordComplexity.minLength &&
                this.oldPassword.length > 0;
        }
    },
    created() {
        this.GET_PROFILE()
            .catch(err => {console.error('security, GET_PROFILE', err)});
    },
    methods: {
        ...mapActions(['GET_PROFILE', 'SEND_EMAIL_VERIFICATION']),

        goChangePassword() {
            this.passwordFormLoader = true;

            const data = {
                oldPassword: this.oldPassword,
                newPassword: this.newPassword
            }

            if (this.oldPassword && this.newPassword) {
                this.$store.dispatch('CHANGE_PASSWORD', data)
                    .then(() => {
                        this.passwordFormLoader = false;

                        if (this.CHANGE_PASSWORD_STATUS === 'success') {
                            this.$store.dispatch('LOGOUT').then(() => {
                                this.$router.push('/password-changed');
                            });
                        } else {
                            this.oldPasswordErrorMessages = ['The current password that you entered is incorrect'];
                        }
                    });
            }
        },
        changeEmail() {
            this.dialogChangeEmailVisible = true;
        },
        resendEmailConfirmation() {
            this.timer = 60;
            this.SEND_EMAIL_VERIFICATION().then(() => {
                this.countDownTimer();
            })
        },
        countDownTimer() {
            if (this.timer > 0) {
                setTimeout(() => {
                    this.timer -= 1;
                    this.countDownTimer();
                }, 1000);
            }
        },
    }
}
</script>
